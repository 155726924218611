const locale = {
	or: "または",
	and: "そして",
	delete_rule: "ルールを削除する",
	add_rule: "ルールを追加",
	add_group: "グループを追加",
	less: "レス",
	less_or_equal: "以下",
	greater: "大きいです",
	greater_or_equal: "以上",
	between: "間に",
	not_between: "いない間",
	begins_with: "で始まります",
	not_begins_with: "ないで始まります",
	contains: "含まれています",
	not_contains: "含まれていません",
	ends_with: "で終わります",
	not_ends_with: "で終わりではありません",
	is_empty: "空であります",
	is_not_empty: "それは空でありません",
	equal: "等しいです",
	not_equal: "等しくありません",
	is_null: "ブランクである",
	is_not_null: "ブランクではない",
	default_option: "---",
	cancel: "キャンセル",
	filter: "フィルタ",
	sort: "ソート"
};

export default locale;