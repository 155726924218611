const locale = {
	or: "Or",
	and: "And",
	delete_rule: "Delete rule",
	add_rule: "Add rule",
	add_group: "Add group",
	less: "less",
	less_or_equal: "less or equal",
	greater: "greater",
	greater_or_equal: "greater or equal",
	between: "between",
	not_between: "not between",
	begins_with: "begins with",
	not_begins_with: "not begins with",
	contains: "contains",
	not_contains: "not contains",
	ends_with: "ends with",
	not_ends_with: "not ends with",
	is_empty: "is empty",
	is_not_empty: "is not empty",
	equal: "equal",
	not_equal: "not equal",
	is_null: "is null",
	is_not_null: "is not null",
	default_option: "---",
	cancel: "Cancel",
	filter: "Filter",
	sort:"Sort",
	sortby: "Sort by",
	asc:"asc",
	desc:"desc"
};

export default locale;