const locale = {
	or: "ou",
	and: "e",
	delete_rule: "Excluir regra",
	add_rule: "Adicionar regra",
	add_group: "Adicionar grupo",
	less: "menos",
	less_or_equal: "menor ou igual",
	greater: "maior",
	greater_or_equal: "maior ou igual",
	between: "entre",
	not_between: "não entre",
	begins_with: "começa com",
	not_begins_with: "não começa com",
	contains: "contém",
	not_contains: "não contém",
	ends_with: "termina com",
	not_ends_with: "não termina com",
	is_empty: "está vazia",
	is_not_empty: "não é vazia",
	equal: "igual",
	not_equal: "não é igual",
	is_null: "é nulo",
	is_not_null: "não é nula",
	default_option: "---",
	cancel: "Cancelar",
	filter: "Filtro",
	sort: "Ordenar"
};

export default locale;