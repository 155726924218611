const sort = {
	_init_sorting:function(){
		const locale = webix.i18n.querybuilder;

		return [{
			view:"multiselect", $id:"by",
			label:locale.sortby,
			suggest: {
				body:{
					data: this.config.fields
				}
			},
			align: "right",
			width: 300,
			labelWidth: 57,
			on: {
				onChange: () => {
					this._callChangeMethod();
				}
			}
		}, {
			view:"richselect", $id:"order",
			options:[{ id:"asc", value:locale.asc}, { id:"desc", value:locale.desc}],
			value:"asc",
			width: 80,
			on: {
				onChange: () => {
					if(this._getSortingValues().sortBy) {
						this._callChangeMethod();
					}
				}
			}
		}, {}];
	},
	_getSortingValues() {
		const els = this.getSortingElements();
		return { sortBy: els[0].getValue(), sortAs: els[1].getValue() };
	},
	_setSortingValues(value) {
		const els = this.getSortingElements();

		if (value.fields){
			var list = els[0].getList();
			list.clearAll();
			list.parse(value.fields);
		}

		els[0].setValue(value.sortBy);
		els[1].setValue(value.sortAs);
	},
	getSortingElements(){
		return [this.queryView({$id:"by"}), this.queryView({$id:"order"})];
	},
	getSortingHelper() {
		const els = this.getSortingElements();
		const by = els[0].getValue();
		const sortAs = els[1].getValue();

		if (!by) return null;
		
		let values = by.split(",").map(id => {
			var item = els[0].getList().getItem(id);
			var type = item.type;
			if (type === "number") type = "int";

			return { 
				by: function(obj){ return obj[id]; },
				as: webix.DataStore.prototype.sorting.as[type]
			};
		});
		
		return function(obj1, obj2){
			var order;
			for (var i=0; i<values.length; i++){
				var sorter = values[i];
				var a = sorter.by(obj1);
				var b = sorter.by(obj2);
				order = sorter.as(a, b);
				if (order !== 0) break;
			}

			return order * ((sortAs === "asc") ? 1 : -1);
		};
	}
};

export default sort;