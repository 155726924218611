const locale = {
	or: "ou",
	and: "et",
	delete_rule: "Supprimer la règle",
	add_rule: "Ajouter une règle",
	add_group: "Ajouter un groupe",
	less: "moins",
	less_or_equal: "inférieur ou égal",
	greater: "plus grand",
	greater_or_equal: "supérieur ou égal",
	between: "entre",
	not_between: "pas entre",
	begins_with: "commence par",
	not_begins_with: "ne commence par",
	contains: "contient",
	not_contains: "ne contient",
	ends_with: "se termine par",
	not_ends_with: "pas se termine par",
	is_empty: "est vide",
	is_not_empty: "Il n'est pas vide",
	equal: "égal",
	not_equal: "pas égal",
	is_null: "est nulle",
	is_not_null: "Il est non nul",
	default_option: "---",
	cancel: "Annuler",
	filter: "Filtre",
	sort: "Trier"
};

export default locale;