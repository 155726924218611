function _buttonCreate(label, click, type) {
	return {
		view:"button",
		value:label,
		align: "right",
		width:120,
		type,
		click
	};
}

webix.ui.datafilter.queryBuilder = webix.extend({
	getInputNode(node){
		return webix.$$(node._comp_id) || null;
	},
	getValue(node) {
		let master = webix.$$(node._comp_id);
		return master.getValue();
	},
	setValue(node, value) {
		let master = webix.$$(node._comp_id);
		master.setValue(value || {});
	},
	refresh(master, node, config) {
		master.registerFilter(node, config, this);
		node._comp_id = master._qb.config.id;
		let qb = webix.$$(node._comp_id).getParentView();

		this.setValue(node, config.value);
		webix.event(node, "click", () => this._filterShow(node, qb));
	},
	render(master, config) {
		const html = "<div class=\"webix_qb_filter\"><i class=\"webix_qb_filter_icon\" aria-hidden=\"true\"></i></div>"+(config.label||"");
		if (config.rendered)
			return html;

		const locale = webix.i18n.querybuilder;
		
		config.css = "webix_ss_filter";

		let filter;
		config.prepare = () => { filter = master._qb.getFilterHelper(); };
		config.compare = (v, i, o) => filter(o);
		
		let qb = {
			view: "querybuilder",
			fields:config.fields
		};
		if (config.queryConfig)
			webix.extend(qb, config.queryConfig);

		let popupView;
		let buttonSave = _buttonCreate(locale.filter, () => {
			if(master._qb) {
				let cfg = master._qb.config;
				if (cfg.sorting){
					const sortconfig = master._qb.getSortingHelper();
					if (sortconfig)
						master.sort(sortconfig);
				}
				if (cfg.filtering)
					master.filterByAll();
				
				popupView.hide();
			}
		}, "form");
		let buttonCancel = _buttonCreate(locale.cancel, () => {
			popupView.hide();
		});

		let body = {margin:5, rows:[qb, {cols:[ buttonSave, buttonCancel, {} ]}]};

		let popup = {
			view:"popup",
			minWidth: 810,
			body
		};

		if(config.popupConfig){
			webix.extend(popup, config.popupConfig, true);
		}

		popupView = webix.ui(popup);
		master._qb = popupView.getBody().getChildViews()[0];
		config.rendered = true;
		master.attachEvent("onDestruct", () => {
			popupView.destructor();
		});

		return html;
	},
	_filterShow(node, qb) {
		qb.show(node.querySelector(".webix_qb_filter"));
	}
}, webix.EventSystem);
